$newKinedu: #0086D8;
$newKineduLight: #EAF5FC;
$kinedu: #278ECD;
$oldkinedu: #1B75BB;
$kineduShade: #165E96;
$kineduShadeDark: #134F7D;


$physicalDark50: #085670;
$physicalDark20: #0689B4;
$physical: #32AFE0;
$physicalLight30: #5DC5EA;
$physicalLight50: #8CD6F0;
$physicalLight70: #BBE6F6;
$physicalLight80: #D9F5FF;
$cognitiveDark50: #395C26;
$cognitiveDark20: #5B933D;
$cognitive: #3EB646;
$cognitiveLight30: #9DCD83;
$cognitiveLight50: #B9DCA7;
$cognitiveLight70: #D4EACA;
$cognitiveLight80: #E9FFDF;
$socialEmotionalDark50: #6A1838;
$socialEmotionalDark20: #AB275A;
$socialEmotional: #D1296D;
$socialEmotionalLight30: #E26F9B;
$socialEmotionalLight50: #EB99B8;
$socialEmotionalLight70: #F3C1D4;
$linguisticDark50: #7C4604;
$linguisticDark20: #C77100;
$linguistic: #F99848;
$linguisticLight30: #FBB15D;
$linguisticLight50: #FCC78C;
$linguisticLight70: #FDDDBA;
$healthDark50: #730000;
$healthDark20: #B90000;
$health: #E50000;
$healthLight30: #EF4B48;
$healthLight50: #F47F7E;
$healthLight70: #F8B3B2;


// Variables
// Color system

// scss-docs-start gray-color-variables
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
// scss-docs-end gray-color-variables

// scss-docs-start color-variables
$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start theme-color-variables
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;
// scss-docs-end theme-color-variables

$success-light: #73B84E;
$pink-dark: #D1296D;