@use "~styles/base/_colors" as colors;

// $font-family-sans-serif:      system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;


// scss-docs-start border-radius-variables
$border-radius:               .25rem !default;
$border-radius-sm:            .2rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-pill:          50rem !default;
// scss-docs-end border-radius-variables


// scss-docs-start box-shadow-variables
$box-shadow:                  0 .5rem 1rem rgba(colors.$black, .15) !default;
$box-shadow-sm:               0 .125rem .25rem rgba(colors.$black, .075) !default;
$box-shadow-lg:               0 1rem 3rem rgba(colors.$black, .175) !default;
$box-shadow-inset:            inset 0 1px 2px rgba(colors.$black, .075) !default;
// scss-docs-end box-shadow-variables
