@use "~styles/base/_size-screen" as screen;
@use "~styles/base/_colors" as colors;
@use "~styles/buttons/_buttons" as buttons;

.modal {
  overflow-y: auto !important;
  border-radius: 0.8rem;
}

.modal-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 2.5rem 0.5rem;
  @include screen.media-fluid(sm) { margin: 1.2rem 2rem; }


  & .modal-btn-primary {
    @extend .btn-success;
    @extend .btn-radius-pill;
    @extend .btn-large;
  }

  .btn-close {
    @extend .btn;
    @extend .btn-radius-pill;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    padding: 1rem;
    color: white;
    background-color: rgba(#000000, 0.6);
    z-index: 1;
  }
}

.modal-header {
  color: rgba(#404040, 0.8);
  font-size: 2rem;
  justify-content: center;
  display: flex;
  position: relative;
  width: 100%;
  font-weight: 700;
}

.modal-body {
  width: 100%;
  & .img-kinedu {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 42px;
    margin: 1rem 0;

    & img {
      height: 100%;
    }
  }

  & .media {
    display: flex;
    height: 300px;
    margin: 1rem auto;
    width: 100%;
    @include screen.media-fluid(lg) { width: 80%; }

    & div {
      height: 100%;
    }
  }

  & .subheader {
    font-weight: 400;
    font-size: 1rem;
    display: block;
    color: #657480;
    margin: 0 auto 1rem auto;
    text-align: center;
    @include screen.media-fluid(lg) { font-size: 1.3rem; }
    @include screen.media-fluid(xxl) { font-size: 1.5rem; }
    
    & .subheader-badge {
      display: inline;
      font-weight: 600;
      padding-left: .4rem;
      color: colors.$newKinedu;
    }
  }
}


.modal-actions {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 1rem 0;
}
