@use "~styles/base/_colors" as colors;
@use "~styles/variables/_variables" as variables;
@use "~styles/base/_size-screen" as screen;

$input-btn-padding-y:         .375rem !default;
$input-btn-padding-x:         .75rem !default;

.btn {
  background-color: transparent;    
  border: 1px solid transparent;
  border-radius: variables.$border-radius;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1;
  padding: $input-btn-padding-y $input-btn-padding-x;
  text-align: center;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  outline: none;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;

  @include screen.media-fluid(md) { 
    font-size: 1rem;
    line-height: 1.5;
  }
}

.btn-link {
  @extend .btn;
  text-decoration: underline;
  font-weight: 500;
}

.btn-radius { border-radius: variables.$border-radius; }
.btn-radius-sm { border-radius: variables.$border-radius-sm; }
.btn-radius-lg { border-radius: variables.$border-radius-lg; }
.btn-radius-pill { border-radius: variables.$border-radius-pill; }


.btn-success {
  @extend .btn;
  background-color: colors.$success-light;
  border-color: colors.$success-light;
  color: colors.$white;
}


.btn-large {
  padding: .5rem 3.5rem;
  line-height: 1.5rem;
}